var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_firmas"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Aprobación Planilla Redireccionamiento ")]),_c('button',{ref:"closeModalFirmas",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.formFirmas),function(firmante,index){return _c('div',{key:index,staticClass:"col-md-4"},[_c('div',{staticClass:"card card-widget widget-user shadow"},[_c('div',{staticClass:"widget-user-bg-header bg-frontera text-white"},[(firmante.firma != undefined)?_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(firmante.firma.user.name.toUpperCase())+" ")]):_vm._e(),_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(firmante.tipo_firma.descripcion)+" ")])]),_c('div',{staticClass:"widget-user-link_pho"},[_c('a',{attrs:{"target":"_blank"}},[(firmante.firma == undefined)?_c('img',{staticClass:"img-fluid img-circle elevation-2",staticStyle:{"height":"128px","width":"128px"},attrs:{"src":"/img/perfil_vacio.png","alt":"User profile picture"}}):_c('img',{staticClass:"profile-user-img img-fluid img-circle elevation-2",staticStyle:{"height":"128px","width":"128px"},attrs:{"src":firmante.firma.user.link_photography
                          ? _vm.uri_docs + firmante.firma.user.link_photography
                          : '/img/perfil_vacio.png',"alt":"User profile picture","href":_vm.uri_docs + firmante.firma.user.link_photography}})])]),_c('div',{staticClass:"card-body pt-5 mt-5"},[(
                      ((!firmante.firma &&
                        firmante.ultima_firma.posicion == index) ||
                        (!firmante.firma && firmante.firma_anterior)) &&
                        _vm.$store.state.user.link_firma &&
                        firmante.users.some(
                          (u) => u.user_id === _vm.$store.state.user.id
                        )
                    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"callout callout-warning"},[_c('small',[_c('strong',[_vm._v(" "+_vm._s(_vm.$store.getters.getAuthAzure ? `La sesión esta iniciada con Azure.` : `Para aprobar el servicio, por favor digite su clave.`)+" ")])])])]),(!_vm.$store.getters.getAuthAzure)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"sign_password"}},[_vm._v("Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pass),expression:"form.pass"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.pass.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.form.pass)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "pass", $event.target.value)}}})]):_vm._e(),(!_vm.$store.getters.getAuthAzure)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"re_sign_password"}},[_vm._v("Confirme Contraseña")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.re_pass),expression:"form.re_pass"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.re_pass.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"password"},domProps:{"value":(_vm.form.re_pass)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "re_pass", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"observaciones"}},[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observacion),expression:"form.observacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.observacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"vtype":"date","rows":"2"},domProps:{"value":(_vm.form.observacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observacion", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"justify-content-center"},[(!_vm.$v.form.$invalid)?_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button","title":"Guardar firma","data-toggle":"tooltip"},on:{"click":function($event){return _vm.save(firmante.tipo_firma.numeracion)}}},[_c('i',{staticClass:"fas fa-signature"}),_vm._m(0,true)]):_vm._e()])])]):_vm._e(),(
                      !_vm.$store.state.user.link_firma &&
                        firmante.users.some(
                          (u) => u.user_id === _vm.$store.state.user.id
                        )
                    )?_c('div',{staticClass:"row"},[_vm._m(1,true)]):_vm._e(),(
                      !firmante.firma &&
                        !firmante.firma_anterior &&
                        firmante.ultima_firma.posicion != index
                    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"callout callout-warning m-0"},[_c('small',[_c('strong',[_vm._v(" A la espera de aprobación del "+_vm._s(_vm.formFirmas[index == 0 ? index : index - 1] .tipo_firma.descripcion)+". ")])])])])]):(firmante.firma)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(firmante.firma.user.link_firma)?_c('b',[_c('img',{staticClass:"img-fluid border border-dark",attrs:{"src":_vm.uri_docs + firmante.firma.user.link_firma}})]):_vm._e(),_c('hr',{staticClass:"border border-dark"}),_c('div',[_c('b',[_vm._v(" Firmado Por: "+_vm._s(firmante.firma.user.name))])]),_c('div',[_c('b',[_vm._v(" Firmado: "+_vm._s(firmante.firma.fecha_firma))])]),_c('div',[_c('b',[_vm._v(" Observación: "+_vm._s(firmante.firma.observaciones))])])])]):_vm._e()])])])}),0)]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")]),(
              _vm.$store.getters.can(
                'hidrocarburos.planillaRedireccionamiento.pdf'
              )
            )?_c('button',{staticClass:"btn btn-sm bg-gradient-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.verPDF(_vm.planilla.id)}}},[_c('i',{staticClass:"fas fa-print"},[_vm._v(" Imprimir Planilla")])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_c('small',[_vm._v("Aprobar Servicio")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"callout callout-warning text-center"},[_c('small',[_c('strong',[_c('i',[_vm._v("Para firmar debe tener una firma digital")])])])])])
}]

export { render, staticRenderFns }